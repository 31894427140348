.what-box {
  margin-top: 200px;
  margin-bottom: 200px;
}

.what-title {
  font-family: oswald, sans-serif;
  font-size: 75px;
}
.we-line {
  background: #ff004f;
  width: 5%;
  height: 5px;
  border: none;
}
.what-pic {
  height: 800px;
  width: 650px;
  object-fit: cover;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  margin-top: 50px;
}
.line-what {
  background: #ff004f;
  width: 100px;
  height: 5px;
  border: none;
}
.title-what {
  display: flex;
  justify-content: center;
  align-items: center;
}
.what-info {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 100px;
}
.what-text {
  font-size: 18px;
  text-align: justify;
  margin-top: 50px;
}
.what-subt {
  color: #ff004f;
  margin-top: 150px;
}
.what-who {
  font-family: oswald, sans-serif;
  font-size: 200px;
  color: #ff004f;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .what-box {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

@media (max-width: 1280px) {
  .what-who {
    font-size: 100px;
  }
  .what-title {
    font-size: 38px;
  }
  .title-what {
    justify-content: end;
    margin-right: 20px;
  }
  .what-info {
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;
  }
  .what-pic {
    height: auto;
    width: 100%;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    margin-top: 0;
  }
  .what-subt {
    margin-top: 20px;
    text-align: center;
  }
  .what-text {
    margin-top: 20px;
    text-align: start;
    padding: 0 20px;
  }
  .what-who {
    text-align: center;
  }
  .what-box {
    margin-top: 80px;
    margin-bottom: 0;
  }
  .title-what {
    margin-bottom: 40px;
  }
  .line-home {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .line-menu {
    width: 80%;
  }
  .line-we {
    width: 70px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1599px) {
  .what-title {
    font-size: 75px;
  }
  .what-who {
    font-size: 200px;
  }
  .what-box {
    margin: 100px 0 0 0;
  }
}
