.logo {
  width: 230px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#menu-logo-container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  background: #ffffff;
  svg {
    width: 230px;
    margin-top: 10px;
    margin-left: 30px;
  }
}

#trigger1 {
  position: relative;
  top: -80px;
}

.menu-animation,
#menu-logo-container {
  transition: transform 0.3s ease-out;
}

.hide-logo {
  transform: translateY(-100px);
}

.hide-menu {
  transform: scale(0);
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  #menu-logo-container {
    margin-top: 0;
    margin-left: 0;
  }
}
