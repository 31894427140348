.we-box {
  margin-top: 50px;
  margin-bottom: 200px;
}
.we-title {
  font-family: oswald, sans-serif;
  font-size: 75px;
}
.we-line {
  background: #ff004f;
  width: 5%;
  height: 5px;
  border: none;
}
.we-pic {
  height: 800px;
  width: 650px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  margin-top: 50px;
  object-fit: cover;
}
.line-we {
  background: #ff004f;
  width: 100px;
  height: 5px;
  border: none;
}
.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.we-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 100px;
}
.we-text {
  font-size: 18px;
  text-align: justify;
  margin-top: 50px;
}
.we-text-container {
  flex-basis: 400px;
}
.we-subt {
  color: #ff004f;
  margin-top: 150px;
}
.we-who {
  font-family: oswald, sans-serif;
  font-size: 200px;
  color: #ff004f;
}

@media screen and (min-width: 200px) and (max-width: 1023px) {
  .we-title {
    font-size: 38px;
  }
  .we-info {
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;
    flex-direction: column;
  }
  .we-pic {
    height: 350px;
    width: 100%;
    margin-top: 20px;
  }
  .we-subt {
    margin-top: 0;
    text-align: center;
  }
  .we-text {
    margin-top: 20px;
    text-align: start;
    padding: 0 20px;
  }
  .we-who {
    font-size: 100px;
    text-align: center;
  }
  .title-container {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 20px;
  }
  .we-box {
    margin-bottom: 80px;
  }

  .line-we {
    width: 70px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1599px) {
  .title-container {
    justify-content: left !important;
    margin-left: 20px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1599px) {
  .we-title {
    font-size: 70px;
  }
  .we-subt {
    font-size: 40px;
  }
  .we-pic {
    height: 900px;
  }
  .we-box {
    margin-bottom: 0;
    margin-top: 0;
  }
}
