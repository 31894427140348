.home-new {
  justify-content: center;
  align-items: center;
  background: #ffffff;
}
.modal-form {
  background: white;
  color: black;
  font-family: oswald;
}
.modal-header .btn-close {
  color: #ff004f;
}
.modal-form-1 {
  background: white;
  color: black;
}
.modal-close {
  background: white;
  color: #ff004f;
  border-width: thin;
  border-color: #ff004f;
  border-style: solid;
  font-family: Poppins, sans-serif;
}
.modal-close:hover {
  background: #ff004f;
  color: white;
  border: none;
}
.form-new {
  height: 560px;
  width: 500px;
  padding: 45px 70px 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.new-title {
  font-family: oswald, sans-serif;
  font-size: 120px;
  width: 560px;
  line-height: 1.1;
  margin-left: 100px;
}
.new-pic-container {
  height: 80vh;
}
.new-pic {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  width: 100%;
  height: 100%;
  padding: 80px;
  object-fit: cover;
}
.box-form {
  position: relative;
}
.form-1 {
  position: absolute;
  left: 70%;
  top: -480px;
}
.field-text {
  color: #ff004f;
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: -25px;
}
.input-box {
  width: 100%;
  margin-top: 60px;
  border: none;
  border-bottom: 1px solid #3b3b3b;
  border-radius: 0;
}
.input-box:focus {
  outline: none;
}
textarea.input-box {
  height: 150px;
  resize: none;
}
.button-form {
  width: 100%;
  height: 100px;
  border: none;
  background: #ff004f;
  opacity: 0.9;
  color: #ede8e6;
  font-size: 20px;
}

@media screen and (min-width: 200px) and (max-width: 1281px) {
  .new-title-mobile {
    font-family: oswald, sans-serif;
    font-size: 38px;
    line-height: 1.1;
    margin-left: 0;
    text-align: start;
    margin-bottom: 30px;
    padding: 0 20px;
  }

  .new-pic-container {
    height: auto;
  }
  .new-pic {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    width: 100%;
    height: 60%;
    padding: 0px;
    object-fit: cover;
  }
  .form-new {
    height: 440px;
    width: 100%;
    padding: 15px 15px 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .form-1 {
    position: relative;
    left: 0;
    top: 0;
    background: white;
    opacity: 0.9;
    margin-bottom: 100px;
  }
  .input-box {
    width: 100%;
    margin-top: 50px;
    border: none;
    border-bottom: 1px solid #3b3b3b;
    border-radius: 0;
  }
  textarea.input-box {
    height: 120px;
    resize: none;
  }
  .button-form {
    width: 100%;
    height: 75px;
    border: none;
    background: #ff004f;
    opacity: 0.9;
    color: #ede8e6;
    font-size: 20px;
  }
  .line-home {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .line-menu {
    width: 80%;
  }
}
@media screen and (min-width: 700px) and (max-width: 1282px) {
  .box-form {
    width: 50%;
    margin: auto;
  }
  .new-pic {
    width: 80%;
  }
  .new-pic-container {
    text-align: center;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1599px) {
  .form-1 {
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 1282px) and (max-width: 1500px) {
  .new-title {
    font-size: 100px;
    width: 560px;
  }
  .form-1 {
    left: 54%;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1800px) {
  .form-1 {
    left: 58%;
  }
}
@media screen and (min-width: 1801px) and (max-width: 2250px) {
  .form-1 {
    left: 64%;
  }
}
