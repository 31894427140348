.logobox {
  top: 0;
  left: 0;
  width: 50vw;
  height: 50vh;
  svg {
    height: 100%;
    width: 100%;
    path,
    rect {
      stroke: #404040;
      stroke-width: 2px;
      fill: none;
    }
  }
}

.logo-draw {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
