.home-new-mobile {
  justify-content: center;
  align-items: center;
  background: #ffffff;
}
.new-title-2 {
  font-family: oswald, sans-serif;
  font-size: 60px;
  width: 100vw;
  line-height: 1.1;
  margin-left: 0;
  text-align: center;
  margin-bottom: 30px;
}
@media screen and (min-width: 846px) and (max-width: 1282px) {
  .new-title-2 {
    width: 600px;
    margin: auto;
    font-size: 90px;
  }
}
