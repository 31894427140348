@import './src/assets/shared-styles/variables';

#preloader-container {
  width: 100vw;
  height: 100vh;
  background: black;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  z-index: 202;

  #berry-logo {
    width: 30vw;
    max-width: 720px;
    position: absolute;

    rect,
    path {
      fill: none;
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
    }
  }
}
