.services-box {
  height: 1200px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.serv-img {
  height: 1050px;
  margin-right: 50px;
}
.services-title {
  color: #ff004f;
  font-family: oswald, sans-serif;
  font-size: 250px;
  transform: rotate(-90deg);
  translate: 280px;
}
.serv-subtitle {
  font-family: oswald, sans-serif;
  font-size: 75px;
}
.line-services {
  background: #ff004f;
  width: 100px;
  height: 5px;
  border: none;
}

.serv-line {
  display: flex;
  justify-content: center;
  align-items: center;
}
.serv-div {
  margin-top: 50px;
}
.text-div {
  font-size: 20px;
  text-align: justify;
}
.serv-cont {
  max-width: 800px;
}
.info-serv {
  margin-top: 50px;
}
.serv-box {
  font-weight: bold;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .services-box {
    justify-content: center;
    height: 100vh;
    width: 100vw;
  }

  .services-title {
    translate: 60px 125px;
    font-size: 160px;
  }
  .info-serv {
    margin-top: 10px;
  }
  .serv-img {
    height: 700px;
    margin-right: 50px;
  }
  .serv-cont {
    flex-basis: 450px;
  }
  .serv-subtitle {
    font-size: 50px;
  }
  .serv-box {
    text-align: start;
    font-size: 20px;
  }
  .text-div {
    font-size: 16px;
  }
  .serv-line {
    justify-content: start;
  }
}
@media screen and (min-width: 200px) and (max-width: 1282px) {
  .services-title {
    font-size: 80px;
    transform: rotate(-360deg);
    translate: 0;
    text-align: center;
  }
  .serv-mobi {
    font-size: 80px;
    font-family: oswald, sans-serif;
    color: #ff004f;
    margin-bottom: 40px;
  }
  .services-box {
    flex-wrap: wrap;
    text-align: center;
    display: block;
  }
  .font-serv {
    width: 0;
  }
  .services-box {
    height: auto;
    width: 100%;
  }
  .serv-subtitle {
    font-size: 38px;
  }
  .text-div {
    font-size: 18px;
    text-align: start;
    padding-right: 30px;
    padding-left: 30px;
  }
  .info-serv {
    margin-top: 30px;
  }
  .serv-box {
    text-align: start;
    font-weight: bold;
    padding: 0 20px 0 20px;
  }
  .serv-line {
    justify-content: start;
    margin-left: 20px;
  }
  .serv-thing {
    font-family: oswald, sans-serif;
    color: #ff004f;
    font-size: 80px;
    text-align: center;
    margin-top: 20px;
  }
  .line-home-serv {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .line-menu {
    width: 80%;
  }
  .line-services {
    width: 70px;
  }
  .serv-div {
    margin-top: 0;
  }
}
@media screen and (min-width: 800px) and (max-width: 1282px) {
  .serv-mobi {
    font-size: 170px;
  }
  .serv-cont {
    margin-left: 50px;
  }
}
