.footer-info {
  justify-content: center;
  align-items: center;
}
.new-title-3 {
  font-family: oswald, sans-serif;
  font-size: 35px;
  width: 100vw;
  line-height: 1.1;
  margin-left: 0;
  padding: 0 20px 70px 20px;
}
.new-title-4 {
  font-family: oswald, sans-serif;
  font-size: 100px;
  width: 50vw;
  line-height: 1.1;
  margin-left: 50px;
  padding: 0 20px 70px 20px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .new-title-4 {
    font-size: 80px;
  }
}
@media screen and (min-width: 1282px) and (max-width: 2560px) {
  .new-title-4 {
    width: 55vw;
  }
}
@media screen and (min-width: 900px) and (max-width: 1282px) {
  .new-title-3 {
    font-size: 70px;
  }
}
