@import './src/assets/shared-styles/variables';

.logo-menu {
  width: 230px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 10px;
  left: 30px;
  transition: all 800ms cubic-bezier(0.86, 0, 0.07, 1);
  opacity: 0;
  z-index: 99999999;
}

.photo-menu {
  height: 500px;
  width: 350px;
  overflow: hidden;
  object-fit: cover;
}

.menu-container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
}

.label-menu {
  position: fixed;
  top: 53px;
  right: 100px;
  color: $menu-background;
  z-index: 2;
}

.navigation {
  position: relative;
}

.navigation__checkbox {
  display: none;
}

.navigation__button {
  position: absolute;
  border-radius: 50%;
  z-index: 300;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 20%);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .navigation__button {
    top: 40px;
    right: 40px;
    height: 40px;
    width: 40px;
  }
}

.navigation__background {
  position: fixed;
  top: 39px;
  right: 49px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $menu-background;
  background-size: cover;
  background-position: center;
  z-index: 100;
  transition: all 800ms cubic-bezier(0.86, 0, 0.07, 1);
  transform: scale(0);
}

@media screen and (min-width: 768px) {
  .navigation__background {
    top: 44px;
    right: 44px;
    height: 35px;
    width: 35px;
  }
}

.navigation__nav {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  height: 100vh;
  opacity: 0;
  width: 100%;
  z-index: 200;
  transition: all 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.navigation__list {
  text-align: center;
  list-style: none;
}

.navigation__item {
  font-family: oswald, sans-serif;
  line-height: 5;
}

.navigation__link:link,
.navigation__link:visited {
  display: inline-block;
  padding: 1rem 2rem;
  color: $font-menu;
  font-size: 2.2rem;
  text-decoration: none;
  transition: all 0.2s;
}

@media (min-width: 768px) {
  .navigation__link:link,
  .navigation__link:visited {
    font-size: 6rem;
    line-height: 0.5;
  }
}

.navigation__link span {
  margin-right: 1.5rem;
  display: inline-block;
}

.navigation__link:hover {
  color: $primary-color;
  transform: scale(1.1);
}

.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(250);
}

.navigation__checkbox:checked ~ .navigation__nav {
  opacity: 1;
  transform: translateX(0);
}

.navigation__checkbox:checked ~ .logo-menu {
  opacity: 1;
}

.navigation__button:hover .navigation__icon::before {
  top: -1rem;
}

.navigation__button:hover .navigation__icon::after {
  top: 1rem;
}

.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent;
}

.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
  top: 0;
  transform: rotate(135deg);
}

.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
  top: 0;
  transform: rotate(-135deg);
}

.hover-effect::after,
.hover-effect::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  transition: transform 0.4s cubic-bezier(0.6, 0, 0.2, 1) 0s;
}

.hover-effect {
  height: 50px;
  width: 50px;
  position: fixed;
  z-index: 201;
  top: 30px;
  right: 40px;
}

.hover-effect::after {
  background: $primary-color;
  transform: scale(1);
}

.hover-effect:hover::after {
  transform: scale(0.25);
}

.hover-effect::before {
  transform: scale(0);
  border: 1px solid $primary-color;
}

.hover-effect:hover::before {
  transform: scale(1);
}

.no-scroll {
  overflow: hidden;
}

@media screen and (min-width: 200px) and (max-width: 639px) {
  .navigation__list {
    line-height: 0;
    height: 50%;
  }
  .menu-container {
    display: block;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
  }
  .photo-menu {
    height: 50%;
    width: 350px;
    overflow: hidden;
    object-fit: cover;
    padding-top: 140px;
    margin-bottom: 20px;
  }
  .hover-effect::after {
    background: $primary-color;
    display: none;
  }
  .hover-effect::before {
    transform: none;
    border: none;
  }

  .hover-effect:hover::before {
    transform: none;
  }
  .navigation__button {
    background: $primary-color;
    transform: scale(0.8);
  }
  .navigation__background {
    top: 38px;
    right: 24px;
  }
  .navigation__link:link,
  .navigation__link:visited {
    padding: 10px;
  }
  .navigation__item {
    line-height: 0.5;
  }
}

@media screen and (min-width: 640px) and (max-width: 1100px) {
  .menu-container {
    display: flex;
    text-align: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
  }

  .navigation__link {
    font-size: 40px !important;
    line-height: 0.3 !important;
  }

  .navigation__item {
    line-height: 0;
    margin: 10px;
  }

  .photo-menu {
    height: 170px;
    width: 300px;
  }
}
