.m-scroll {
  display: flex;
  position: relative;
  width: 100%;
  height: 500px;
  margin: auto;
  background-color: white;
  overflow: hidden;

  &__title {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    transform: scale(2);
    transition: all 1s ease;
    & > div {
      display: flex;
      animation: scrollText 33s infinite linear;
    }
    .font-h1 {
      margin: 0;
      font-size: 120px;
      transition: all 2s ease;
      font-family: oswald, sans-serif;
      color: #11100e;
    }

    .font-h1:hover {
      color: #ff004f;
    }
  }
}

/*div:hover {
  animation-play-state: paused;
}*/
@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 200px) and (max-width: 699px) {
  .font-h1 {
    font-size: 40px !important;
  }
  .m-scroll {
    height: 100px;
  }
}

@media screen and (min-width: 700px) and (max-width: 1282px) {
  .font-h1 {
    font-size: 80px !important;
  }
  .m-scroll {
    height: 300px;
  }
}
