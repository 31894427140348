@import './src/assets/shared-styles/variables';

body {
  font-family: Poppins, sans-serif;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  overscroll-behavior: contain;
}

html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: calibre;
  src: local('calibre'), url('../fonts/calibre/CalibreSemibold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: oswald;
  src: local('oswald'), url('../fonts/oswald/Oswald-Medium.ttf') format('truetype');
}
@font-face {
  font-family: lato;
  src: local('lato'), url('../fonts/lato/Lato-Medium.ttf') format('truetype');
}

@font-face {
  font-family: oswald;
  src: local('oswald'), url('../fonts/Oswald-Medium.ttf') format('truetype');
  font-weight: bold;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.mobile {
  display: none;
}
.desktop {
  display: block;
}
p {
  margin: 0;
}
ul {
  margin-top: 0;
  margin-bottom: 0;
}
@media screen and (min-width: 200px) and (max-width: 1282px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .new-title-2 {
    margin: auto !important;
  }
  .we-info {
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;
    flex-direction: column;
  }
  .we-subt {
    margin-top: 0 !important;
    text-align: center;
  }
  .we-text {
    padding: 0 50px;
  }
  .we-who {
    text-align: center;
  }
  .we-box {
    margin-bottom: 0 !important;
  }
  .we-pic {
    width: 100% !important;
  }
}

ul {
  padding: 0;
}

section {
  min-height: 100vh;
}
hr {
  opacity: 1;
}
