.cursor {
  pointer-events: none;

  &__ball {
    position: fixed;
    top: 0;
    left: 0;
    mix-blend-mode: difference;
    z-index: 100000;

    circle {
      fill: #f7f8fa;
    }
  }
}
* {
  cursor: none;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .cursor {
    &__ball {
      display: none;
      circle {
        display: none;
      }
    }
    display: none;
  }
  * {
    cursor: auto;
  }
}
