@import './src/assets/shared-styles/variables';

.contact-grid {
  background-color: $primary-color;
  padding: 60px 120px;
  margin: 0 55px 30px;
  border-radius: 20px;

  .field-text-1 {
    color: white;
  }
  .modal-form {
    background: white;
    color: black;
    font-family: oswald;
  }
  .modal-header .btn-close {
    color: #ff004f;
  }
  .modal-form-1 {
    background: white;
    color: black;
  }
  .modal-close {
    background: white;
    color: #ff004f;
    border-width: thin;
    border-color: #ff004f;
    border-style: solid;
    font-family: Poppins, sans-serif;
  }
  .modal-close:hover {
    background: #ff004f;
    color: white;
    border: none;
  }
  form {
    max-width: 420px;
    margin: 50px auto;
  }

  .feedback-input,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    background-color: lighten($primary-color, 20);
    transition: all 0.3s;
    padding: 13px;
    margin-bottom: 15px;
    width: 100%;
    border: none;
    color: $font-menu;
  }

  textarea {
    height: 150px;
    line-height: 150%;
    resize: vertical;
  }

  textarea::placeholder {
    color: $font-menu;
    font-weight: normal;
  }

  input::placeholder {
    color: $font-menu;
    font-weight: normal;
  }

  [type='submit'] {
    width: 30%;
    background: lighten($primary-color, 20);
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: all 0.3s;
    margin-top: -4px;
    font-weight: 600;
    border: none;
    color: $font-menu;
    float: right;
  }

  [type='submit']:hover {
    background: lighten($primary-color, 20);
  }
}

.contact-column {
  width: 100%;
}

.title-contact {
  color: $font-menu;
  font-size: 30px;
  border-bottom: 1px solid $font-menu;
  padding-bottom: 20px;
}

.phone-div {
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 30px;
}

.phone-container {
  margin-top: 20px;
}

.phone-icon {
  font-size: 40px;
  color: $font-menu;
}

.numbers-info {
  font-size: 22px;
  color: $font-menu;
}

.info-contact {
  font-size: 20px;
  color: $font-menu;
}

.red-div {
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 30px;
  margin-top: 20px;
}

.red-icon {
  font-size: 50px;
  color: $font-menu;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .contact-grid {
    background-color: $primary-color;
    padding: 0;
    margin: 0 10px 0 10px;
    border-radius: 20px;

    form {
      max-width: 420px;
      margin: 50px auto;
      padding: 0px 20px;
    }

    .feedback-input,
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      background-color: lighten($primary-color, 20);
      transition: all 0.3s;
      padding: 13px;
      margin-bottom: 15px;
      width: 100%;
      border: none;
      color: $font-menu;
    }

    textarea {
      height: 150px;
      line-height: 150%;
      resize: vertical;
    }

    textarea::placeholder {
      color: $font-menu;
      font-weight: normal;
    }

    input::placeholder {
      color: $font-menu;
      font-weight: normal;
    }

    [type='submit'] {
      width: 30%;
      background: lighten($primary-color, 20);
      font-size: 18px;
      padding-top: 10px;
      padding-bottom: 10px;
      transition: all 0.3s;
      margin-top: -4px;
      font-weight: 600;
      border: none;
      color: $font-menu;
      float: right;
    }

    [type='submit']:hover {
      background: lighten($primary-color, 20);
    }
  }
  .phone-div,
  .red-div {
    justify-content: center;
  }
  .title-contact {
    text-align: center;
    padding: 10px;
    margin: 0 10px 0 10px;
    border-top: 1px solid $font-menu;
  }
}
