.img-game {
  display: block;
  height: 250px;
  width: 200px;
  object-fit: cover;
}

#wbox span {
  font-size: 200px;
}

.test {
  font-size: 300px;
}

@media only screen and (max-width: 600px) {
  #wbox span {
    font-size: 42px;
  }

  .test {
    font-size: 42px;
  }
}

#berry-letters-wrap {
  width: 100%;
  height: 80vh;
}

#berry-letters-gravity {
  width: 100%;
  height: 100%;
}
