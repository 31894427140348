@import './src/assets/shared-styles/variables';

@mixin white-gradient {
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

$animationSpeed: 60s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 14));
  }
}

// Styling
.slider {
  background: $menu-background;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 200px;
  overflow: hidden;
  position: relative;
  width: 100%;
  opacity: 0.8;
  margin-bottom: 100px;
  margin-top: 100px;

  &::before,
  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(250px * 28);
  }

  .slide {
    height: 200px;
    width: 250px;
    text-align: center;

    .img- {
      &1 {
        height: 100%;
        width: 140px;
        object-fit: contain;
      }
      &2 {
        height: 100%;
        width: 150px;
        object-fit: contain;
      }
      &3 {
        height: 100%;
        width: 120px;
        object-fit: contain;
      }
      &4 {
        height: 100%;
        width: 140px;
        object-fit: contain;
      }
      &5 {
        height: 100%;
        width: 180px;
        object-fit: contain;
      }
      &6 {
        height: 100%;
        width: 90px;
        object-fit: contain;
      }
      &7 {
        height: 100%;
        width: 90px;
        object-fit: contain;
      }
      &8 {
        height: 100%;
        width: 150px;
        object-fit: contain;
      }
      &9 {
        height: 100%;
        width: 210px;
        object-fit: contain;
      }
      &10 {
        height: 100%;
        width: 190px;
        object-fit: contain;
      }
      &11 {
        height: 100%;
        width: 170px;
        object-fit: contain;
      }
      &12 {
        height: 100%;
        width: 120px;
        object-fit: contain;
      }
      &13 {
        height: 100%;
        width: 170px;
        object-fit: contain;
      }
      &14 {
        height: 100%;
        width: 110px;
        object-fit: contain;
      }
    }
  }
}
